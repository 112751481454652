import {Component, Input, OnInit} from '@angular/core';
import {BugReportService} from '../../../services/bug-report.service';
import {MDBModalRef, ToastService} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-bug-report-modal',
  templateUrl: './bug-report-modal.component.html',
  styleUrls: ['./bug-report-modal.component.scss']
})
export class BugReportModalComponent implements OnInit {
  bugReport: string;
  loading: boolean;
  @Input() title = 'Submit Bug Report';
  @Input() inputLabel = 'Bug Report';
  @Input() description: 'We\'re sorry you encountered a bug! Please provide as much detail as you can, and we\'ll work on it asap.';

  constructor(private bugReportService: BugReportService,
              private toastr: ToastService,
              public modalRef: MDBModalRef) { }

  ngOnInit() {
  }

  submitBugReport() {
    this.loading = true;
    this.bugReportService.submitBugReport(window.location.href, this.bugReport).subscribe(d => {
      this.bugReport = '';
      this.loading = false;
      this.toastr.info('Bug report received');
      this.modalRef.hide();
    }, () => {
      this.loading = false;
    });
  }
}
