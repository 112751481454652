import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {ImageService} from '../../../services/image.service';
import {CropInstructions} from '../../../../models/crop-instructions.model';
import {MDBModalRef, MDBModalService, ToastService} from 'ng-uikit-pro-standard';
import {Observable, of, Subject} from 'rxjs';
import {OpenCriticImage} from '../../../../models/image.model';
import {CropImageModalComponent} from '../crop-image-modal/crop-image-modal.component';
import {Game} from '../../../../models/game.model';

@Component({
  selector: 'app-image-upload-modal',
  templateUrl: './image-upload-modal.component.html',
  styleUrls: ['./image-upload-modal.component.scss']
})
export class ImageUploadModalComponent implements OnInit {
  @Input() endpoint: string;
  @Input() crop?: boolean;
  @Input() forceCrop?: boolean;
  @Input() targetWidth?: number;
  @Input() targetHeight?: number;
  @Input() targetAspectRatio?: number;
  @Input() fileType?: string;
  @Input() showGameSearch?: boolean;
  @Input() url?: string;
  @Input() thumbnailWidth ?: number;
  @Input() thumbnailHeight ?: number;
  @Input() key ?: string;

  mode: string;
  imageUrl: string;
  cropInstructions: CropInstructions;
  file: File;
  fileFormValid = false;
  loading: boolean;
  action: Subject<Observable<OpenCriticImage>> = new Subject();
  imageSearchLoading: boolean;
  imageResults: string[];
  selectedGame: Game;

  constructor(
    public activeModal: MDBModalRef,
    private toastr: ToastService,
    private modalService: MDBModalService,
    private imageService: ImageService) {
  }

  ngOnInit(): void {
    this.mode = 'initial';
    if (this.url) {
      if (this.url.startsWith('//')) {
        this.url = window.location.protocol + this.url;
      }
      this.imageUrl = this.url;
      this.mode = 'url';
      this.uploadByUrl();
    }
  }

  uploadByUrl(): void {
    if (this.crop || this.forceCrop) {
      this.cropModal(this.imageUrl);
    } else {
      const subscription = this.imageService.uploadByUrl(
        this.endpoint,
        this.imageUrl,
        this.cropInstructions,
        this.key,
        this.fileType,
        this.thumbnailWidth,
        this.thumbnailHeight
        );
      this.action.next(subscription);
      this.action.complete(); // Might be a bad idea?
      this.activeModal.hide();
    }
  }

  cropModal(imageUrl: string) {
    const dialog = this.modalService.show(CropImageModalComponent, {
      class: 'modal-lg modal-info modal-notify modal-max',
      containerClass: 'modal-overflow',
      data: {
        endpoint: this.endpoint,
        imageUrl,
        targetWidth: this.targetWidth,
        targetHeight: this.targetHeight,
        targetAspectRatio: this.targetAspectRatio,
        fileType: this.fileType,
        thumbnailWidth: this.thumbnailWidth,
        thumbnailHeight: this.thumbnailHeight,
        key: this.key
      }
    });
    dialog.content.action.subscribe(subscription => {
      dialog.hide();
      this.action.next(subscription);
      this.action.complete(); // Might be a bad idea?
      this.activeModal.hide();
    });
  }

  onFileSubmit(): void {
    const formData = new FormData();
    formData.append('image', this.file);
    if (this.crop || this.forceCrop) {
      this.loading = true;
      formData.append('cropNeeded', 'true');
      this.imageService.fileUpload(this.endpoint, formData).subscribe((img: any) => {
        this.loading = false;
        this.cropModal(img.key);
      }, () => {
        this.loading = false;
        this.toastr.error('Error while uploading image');
      });
    } else {
      if (this.fileType) {
        formData.append('fileType', this.fileType);
      }
      if (this.cropInstructions) {
        formData.append('cropData', JSON.stringify(this.cropInstructions));
      }
      this.uploadFile(formData);
    }
  }

  fileSelected(event): void {
    if (event.srcElement.files.length > 0) {
      this.fileFormValid = true;
      this.file = event.srcElement.files[0];
    } else {
      this.fileFormValid = false;
    }
  }

  uploadFile(formData: FormData): void {
    this.action.next(this.imageService.fileUpload(this.endpoint, formData));
    this.action.complete(); // May be a bad idea
    this.activeModal.hide();
  }

  gameSelected(game: Game): void {
    this.imageSearchLoading = true;
    this.mode = 'gameSelected';
    this.selectedGame = game;
    this.imageService.getImageList('game/' + game.id + '/o/', '').subscribe(data => {
      this.imageSearchLoading = false;
      this.imageResults = data.Contents;
    });
  }

  selectedImage(img): void {
    if (!this.forceCrop && !this.crop) {
      this.action.next(of(img));
      this.action.complete();
      this.activeModal.hide();
    } else {
      this.cropModal('https:' + img.fullRes);
    }
  }
}
