import {Pipe, PipeTransform} from '@angular/core';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const longMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

@Pipe({name: 'UtcDatePipe'})
export class UtcDatePipe implements PipeTransform {
  transform(value: Date): string {
    return months[value.getUTCMonth()] + ' ' + value.getUTCDate() + ', ' + value.getUTCFullYear();
  }
}

@Pipe({name: 'UtcDatePipeShort'})
export class UtcDatePipeShort implements PipeTransform {
  transform(value: Date): string {
    return months[value.getUTCMonth()] + ' ' + value.getUTCDate();
  }
}


@Pipe({name: 'UtcDatePipeFlex'})
export class UtcDatePipeFlex implements PipeTransform {
  transform(value: Date, showFull: boolean, showShort = false): string {
    return ((showShort) ? months[value.getUTCMonth()] : longMonths[value.getUTCMonth()])
      + ' ' + value.getUTCDate() + (showFull ? ', ' + value.getUTCFullYear() : '');
    // if (showFull) {
    //   return longMonths[value.getUTCMonth()] + ' ' + value.getUTCDate() + ', ' + value.getUTCFullYear();
    // }
    // return longMonths[value.getUTCMonth()] + ' ' + value.getUTCDate();
  }
}
