import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DiscoveredSku, ExternalSource, ExternalSourceSearchResult, GameExternal, GameImport} from '../../models/game-external.model';
import {Review} from '../../models/review.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GameExternalService {

  constructor(private http: HttpClient) { }

  getByGameId(id: string): Observable<GameExternal> {
    return this.http.get<GameExternal>('/api/external-source/game/' + id);
  }

  getExternalSourcesCount(): Observable<{count: number}> {
    return this.http.get<{count: number}>('/api/external-source/sku/count');
  }

  getEpicChosenCount(): Observable<{count: number}> {
    return this.http.get<{count: number}>('/api/external-source/epic/count');
  }

  getNextEpicChosen(id: string): Observable<Review[]> {
    return this.http.get<Review[]>('/api/external-source/chosen-reviews/' + id);
  }

  getDiscoveredSkuById(id: string): Observable<DiscoveredSku> {
    return this.http.get<DiscoveredSku>('/api/external-source/sku/' + id);
  }

  getRecentSkus(): Observable<DiscoveredSku[]> {
    return this.http.get<DiscoveredSku[]>('/api/external-source/sku/recent');
  }

  saveSku(sku: DiscoveredSku): Observable<DiscoveredSku> {
    return this.http.post<DiscoveredSku>('/api/external-source/sku', sku);
  }

  setGameChosen(GameId: number): Observable<any> {
    return this.http.post<any>('/api/external-source/sku/chosen', {GameId});
  }

  generateSources(phrase: string): Observable<ExternalSourceSearchResult[]> {
    return this.http.post<ExternalSourceSearchResult[]>('/api/external-source/search', {phrase});
  }

  saveSources(ext: GameExternal): Observable<GameExternal> {
    return this.http.post<GameExternal>('/api/external-source', ext);
  }

  importGameData(id: number): Observable<GameImport[]> {
    return this.http.get<GameImport[]>('/api/external-source/import/' + id).pipe(map(sources => {
      sources.forEach(source => {
        if (!source.isError && !source.noGameData) {
          if (source.gameData.Images && source.gameData.Images.length) {
            source.gameData.Images = source.gameData.Images.map(image => ({externalUrl: image, loading: false}));
          }
          if (source.gameData.Platforms && source.gameData.Platforms.length) {
            source.gameData.Platforms.forEach(platform => {
              if (platform.releaseDate) {
                platform.releaseDate = new Date(platform.releaseDate);
              }
            });
          }
          if (source.gameData.Companies && source.gameData.Companies.length > 0) {
            source.gameData.Companies = source.gameData.Companies.filter(company => !!company.name && company.name.length > 3);
          }
        }
      });
      return sources;
    }));
  }

  getCount(): Observable<{count: number}> {
    return this.http.get<{count: number}>('/api/external-source/game/count');
  }

  skip(GameId: number): Observable<any> {
    return this.http.post<any>('/api/external-source/hold', {GameId});
  }

  getRecent(): Observable<GameExternal[]> {
    return this.http.get<GameExternal[]>('/api/external-source/recent');
  }
}
