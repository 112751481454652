import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayNames'
})
export class ArrayNamesPipe implements PipeTransform {

  transform(value: any[]): any {
    return value.map(v => v.name).join(', ');
  }

}
