import {Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {OpenCriticImage} from '../../models/image.model';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {ImageUploadModalComponent} from '../components/modals/image-upload-modal/image-upload-modal.component';
import {CropImageModalComponent} from '../components/modals/crop-image-modal/crop-image-modal.component';

@Directive({
  selector: '[appCropImage]'
})
export class CropImageTriggerDirective {
  @Input() endpoint: string;
  @Input() targetWidth ?: number;
  @Input() targetHeight ?: number;
  @Input() targetAspectRatio ? = 1920 / 1080;
  @Input() fileType ?: string;
  @Input() imageUrl: string;
  @Input() key ?: string;
  @Output() imageSubmitted = new EventEmitter<Observable<OpenCriticImage>>();

  modalRef: MDBModalRef;

  @HostBinding('style.cursor')
  cursor = 'pointer';

  constructor(private elem: ElementRef, private modalService: MDBModalService) {
  }

  @HostListener('click') onClick() {
    if (this.targetWidth && this.targetHeight) {
      this.targetAspectRatio = this.targetWidth / this.targetHeight;
    }
    this.modalRef = this.modalService.show(CropImageModalComponent, {
      class: 'modal-lg modal-info modal-notify modal-max',
      containerClass: 'modal-overflow',
      data: {
        endpoint: this.endpoint,
        imageUrl: this.imageUrl,
        targetWidth: this.targetWidth,
        targetHeight: this.targetHeight,
        targetAspectRatio: this.targetAspectRatio,
        fileType: this.fileType,
        key: this.key
      }
    });
    this.modalRef.content.action.subscribe(d => {
      this.imageSubmitted.emit(d);
    });
  }
}
