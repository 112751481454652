import {Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {OpenCriticImage} from '../../models/image.model';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {ImageUploadModalComponent} from '../components/modals/image-upload-modal/image-upload-modal.component';
import {CropImageModalComponent} from '../components/modals/crop-image-modal/crop-image-modal.component';

@Directive({
  selector: '[appImageCropAndUploadTrigger]'
})
export class CropUploadImageTriggerDirective {
  @Input() endpoint: string;
  @Input() crop ? = false;
  @Input() showGameSearch ? = false;
  @Input() forceCrop ? = false;
  @Input() targetWidth ?: number;
  @Input() targetHeight ?: number;
  @Input() targetAspectRatio ? = 1920 / 1080;
  @Input() fileType ?: string;
  @Input() thumbnailHeight ?: number;
  @Input() thumbnailWidth ?: number;
  @Input() url: string;
  @Input() key ?: string;
  @Output() imageSubmitted = new EventEmitter<Observable<OpenCriticImage>>();

  modalRef: MDBModalRef;

  @HostBinding('style.cursor')
  cursor = 'pointer';

  constructor(private elem: ElementRef, private modalService: MDBModalService) {
  }

  @HostListener('click') onClick() {
    // this.modalRef = this.modalService.show(ImageUploadModalComponent, {
    //   class: 'modal-xl modal-info modal-notify',
    //   data: {
    //     endpoint: this.endpoint,
    //     crop: this.crop,
    //     showGameSearch: this.showGameSearch,
    //     forceCrop: this.forceCrop,
    //     targetHeight: this.targetHeight,
    //     targetWidth: this.targetWidth,
    //     targetAspectRatio: (this.targetHeight && this.targetWidth ? this.targetWidth / this.targetHeight : this.targetAspectRatio),
    //     fileType: this.fileType,
    //     url: this.url,
    //     thumbnailHeight: this.thumbnailHeight,
    //     thumbnailWidth: this.thumbnailWidth,
    //   }
    // });
    const imageUrl = (this.url.startsWith('//')) ? window.location.protocol + this.url : this.url;
    console.log(imageUrl);
    this.modalRef = this.modalService.show(CropImageModalComponent, {
      class: 'modal-lg modal-info modal-notify modal-max',
      containerClass: 'modal-overflow',
      data: {
        endpoint: this.endpoint,
        imageUrl,
        targetWidth: this.targetWidth,
        targetHeight: this.targetHeight,
        targetAspectRatio: this.targetAspectRatio ? this.targetAspectRatio : this.targetWidth / this.targetHeight,
        fileType: this.fileType,
        thumbnailWidth: this.thumbnailWidth,
        thumbnailHeight: this.thumbnailHeight,
        key: this.key
      }
    });
    this.modalRef.content.action.subscribe(d => {
      this.modalRef.hide();
      this.imageSubmitted.emit(d);
    });
  }
}
