import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {YouTubeChannel} from '../../../../models/youtube-channel.model';
import {YoutubeService} from '../../../services/youtube.service';
import {MDBModalRef} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-channel-search-modal',
  templateUrl: './channel-search-modal.component.html',
  styleUrls: ['./channel-search-modal.component.scss']
})
export class ChannelSearchModalComponent implements OnInit {
  @Input() initialSearch: string;
  @Input() initialChannel: YouTubeChannel;
  @Input() title: string;
  @Input() channelSelected: EventEmitter<YouTubeChannel>;
  @Input() possibleSearches: string[];

  channel: YouTubeChannel;
  searchStr: string;
  loading = false;
  page: number;
  results: YouTubeChannel[][];
  nextPageToken: string;

  constructor(private youtube: YoutubeService,
              public activeModal: MDBModalRef) { }

  ngOnInit() {
    this.channel = {...this.initialChannel};
    this.searchStr = '' + this.initialSearch;
    if (this.initialSearch) {
      this.search();
    }
  }

  search() {
    this.loading = true;
    this.results = [];
    this.page = 0;
    this.youtube.channelSearch(this.searchStr).subscribe(data => {
      this.results.push(data.channelResults);
      this.nextPageToken = data.nextPageToken;
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  nextPage() {
    this.loading = true;
    this.page++;
    this.youtube.channelSearch(this.searchStr, this.nextPageToken).subscribe(data => {
      this.results.push(data.channelResults);
      this.nextPageToken = data.nextPageToken;
      this.loading = false;
    }, () => {
      this.page--;
      this.loading = false;
    });
  }
}
