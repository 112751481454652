import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Application} from '../../models/application.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private http: HttpClient) { }

  static transformApplication(app: Application): Application {
    if (app.createdAt) {
      app.createdAt = new Date(app.createdAt);
    }
    if (app.updatedAt) {
      app.updatedAt = new Date(app.updatedAt);
    }
    return app;
  }

  static transformApplications(apps: Application[]): Application[] {
    return apps.map(ApplicationService.transformApplication);
  }

  getApplications(): Observable<Application[]> {
    return this.http.get<Application[]>('/api/application').pipe(map(ApplicationService.transformApplications));
  }

  getCount(): Observable<{ count: number }> {
    return this.http.get<{count: number}>('/api/application/count');
  }

  getActiveApplications(): Observable<Application[]> {
    return this.http.get<Application[]>('/api/application/active').pipe(map(ApplicationService.transformApplications));
  }

  submitApplication(type: string, app: Application): Observable<Application> {
    return this.http.post<Application>('/api/application/' + type, app);
  }

  saveApplication(app: Application): Observable<Application> {
    return this.http.post<Application>('/api/application/manage', app);
  }
}
