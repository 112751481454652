import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Outlet} from '../../models/outlet.model';

@Injectable({
  providedIn: 'root'
})
export class OutletService {

  constructor(private http: HttpClient) { }

  search(criteria: string): Observable<Outlet[]> {
    if (!criteria || criteria.length < 2) {
      return of([] as Outlet[]);
    }
    return this.http.get<Outlet[]>('/api/outlet/search?criteria=' + encodeURIComponent(criteria));
  }

  get(id: number): Observable<Outlet> {
    return this.http.get<Outlet>('/api/outlet/' + id);
  }

  create(data: any): Observable<Outlet> {
    return this.http.post<Outlet>('/api/outlet', data);
  }

  save(outlet: Outlet): Observable<Outlet> {
    return this.http.put<Outlet>('/api/outlet', outlet);
  }
}
