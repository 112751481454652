import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {YouTubeChannel} from '../../models/youtube-channel.model';
import {MDBModalService} from 'ng-uikit-pro-standard';
import {ChannelSearchModalComponent} from '../components/modals/channel-search-modal/channel-search-modal.component';

@Directive({
  selector: '[appChannelSearchTrigger]'
})
export class ChannelSearchDirective {
  @Input() initialSearch: string;
  @Input() title: string;
  @Input() initialChannel: YouTubeChannel;
  @Input() possibleSearches: string[];
  @Output() channelSelected  = new EventEmitter<YouTubeChannel>();

  constructor(private elem: ElementRef, private modalService: MDBModalService) {
  }

  @HostListener('click') onClick() {
    const modalRef = this.modalService.show(ChannelSearchModalComponent, {
      class: 'modal-lg modal-info modal-notify',
      data: {
        initialSearch: this.initialSearch,
        initialChannel: this.initialChannel,
        title: this.title,
        channelSelected: this.channelSelected,
        possibleSearches: this.possibleSearches
      }
    });
  }
}
