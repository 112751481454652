import {Component, Input, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Review} from '../../../../models/review.model';
import {MDBModalRef} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-review-override-modal',
  templateUrl: './review-override-modal.component.html',
  styleUrls: ['./review-override-modal.component.scss']
})
export class ReviewOverrideModalComponent implements OnInit {
  @Input() oldReview: Review;
  @Input() currentReview: Review;

  action: Subject<Observable<string>> = new Subject();

  constructor(public activeModal: MDBModalRef) { }

  ngOnInit() {
  }

  submit(result) {
    this.action.next(result);
    this.action.complete(); // May be a bad idea
    this.activeModal.hide();
  }
}
