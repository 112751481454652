import {throwError as observableThrowError, Observable, of} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';


import {Router} from '@angular/router';
import {ToastService} from 'ng-uikit-pro-standard';

@Injectable()
export class ErrorResponseInterceptor implements HttpInterceptor {

    constructor(private toastr: ToastService, private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError((err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 400) {
                    if (err.error.message && err.error.message.otherReviews) {
                        return observableThrowError(err);
                    } else if (err.error.message) {
                      if (err.error.message.message) {
                        this.toastr.error(err.error.message.message);
                      } else {
                        this.toastr.error(err.error.message);
                      }
                    } else if (err.error.loginRefresh) {
                        this.toastr.warning('Login refresh needed.');
                        this.router.navigate(['login']);
                    } else {
                        this.toastr.error('An unknown error occured when attempting to contact the server');
                    }
                }
                if (err.status === 401) {
                    if (err.error.indexOf('UnauthorizedError: No authorization token was found') >= 0) {
                        this.toastr.warning('Please try logging in again.');
                        this.router.navigate(['login']);
                    }
                }
            }
            return observableThrowError(err);
        }));
    }
}
