import {Component, Input, OnInit} from '@angular/core';
import {MDBModalRef, ToastService} from 'ng-uikit-pro-standard';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-html-editor-modal',
  templateUrl: './html-editor-modal.component.html',
  styleUrls: ['./html-editor-modal.component.scss']
})
export class HtmlEditorModalComponent implements OnInit {
  @Input() heading: string;

  html: string;
  subjectLine: string;
  action: Subject<{body: string, subject: string}> = new Subject();

  constructor(public activeModal: MDBModalRef,
              private toastr: ToastService) { }

  ngOnInit() {
  }

  submit() {
    this.action.next({body: this.html, subject: this.subjectLine});
    this.action.complete(); // Might be a bad idea?
    this.activeModal.hide();
  }
}
