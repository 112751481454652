import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Article} from '../../models/article.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SocialService {

  constructor(private http: HttpClient) { }

  postMessage(network: string, message: string, id: number, title: string, route: string) {
    return this.http.post<any>('/api/social/' + network, {message, id, title, route});
  }
}
