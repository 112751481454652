import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BugReportService {

  constructor(private http: HttpClient) { }

  submitBugReport(page: string, description: string): Observable<any> {
      return this.http.post('/api/bug-report', {page, description});
  }
}
