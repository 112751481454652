import {Component, Input, OnInit} from '@angular/core';
import {AuthorService} from '../../../services/author.service';
import {ToastService} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-bulk-author-modal',
  templateUrl: './bulk-author-modal.component.html',
  styleUrls: ['./bulk-author-modal.component.scss']
})
export class BulkAuthorModalComponent implements OnInit {
  @Input() authorLinks: any[];

  constructor(private authorService: AuthorService,
              private toastr: ToastService) { }

  ngOnInit() {
  }

  addAuthor(name: string) {
    this.authorService.newAuthor(name, true).subscribe(d => {
      this.toastr.info(d.name + ' added');
    });
  }
}
