import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Author} from '../../../models/author.model';
import {AuthorService} from '../../services/author.service';

@Component({
  selector: 'app-author-search-bootstrap',
  templateUrl: './author-search-bootstrap.component.html',
  styleUrls: ['./author-search-bootstrap.component.scss']
})
export class AuthorSearchBootstrapComponent implements OnInit {
  @Input() disabled ?= false;
  @Input() initialAuthor ?: Author;
  @Input() clearSelection ?: false;
  @Input() showAddAuthor ?= false;
  @Output() selected = new EventEmitter<Author>();
  selectedAuthor: Author;
  loading = false;
  searchStr: string;
  searchResults: Author[];
  mostRecentResults: number;
  minLength = 3;

  constructor(private authorService: AuthorService) { }

  ngOnInit() {
  }

  authorSelected(result: Author): void {
    this.selectedAuthor = result as Author;
    this.selected.emit(this.selectedAuthor);
    this.searchResults = null;
    this.mostRecentResults = Date.now();
    this.searchStr = '';
    if (this.clearSelection) {
      this.selectedAuthor = null;
    }
  }

  addAuthor(): void {
    this.loading = true;
    this.authorService.newAuthor(this.searchStr, true)
      .subscribe(data => {
        this.authorSelected(data);
        this.loading = false;
      }, () => {
        this.loading = false;
      });
  }

  onChange() {
    this.selected.emit(null);
    if (this.searchStr && this.searchStr.length >= this.minLength) {
      const searchTime = Date.now();
      this.mostRecentResults = searchTime;
      this.authorService.search(this.searchStr).subscribe(results => {
        if (this.mostRecentResults <= searchTime) {
          this.mostRecentResults = searchTime;
          this.searchResults = results;
        }
      });
    } else {
      this.searchResults = null;
      this.mostRecentResults = Date.now();
    }
  }
}
