import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Article} from '../../models/article.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private http: HttpClient) { }

  public static transform(article: Article): Article {
    if (article.publishedDate) {
      article.publishedDate = new Date(article.publishedDate);
    }
    return article;
  }

  list(skip?: number): Observable<Article[]> {
    return this.http.get<Article[]>('/api/article' + (skip ? '?skip=' + skip : '')).pipe(map(list => list.map(ArticleService.transform)));
  }

  get(id: string): Observable<Article> {
    return this.http.get<Article>('/api/article/' + id).pipe(map(article => ArticleService.transform(article)));
  }

  save(article: Article): Observable<Article> {
    return this.http.put<Article>('/api/article', article).pipe(map(a => ArticleService.transform(a)));
  }

  create(): Observable<Article> {
    return this.http.post<Article>('/api/article', {}).pipe(map(a => ArticleService.transform(a)));
  }

  delete(article: Article): Observable<any> {
    return this.http.delete('/api/article/' + article._id);
  }

  getTeam(): Observable<{id: number, name: string, title: string, order: number}[]> {
    return this.http.get<{id: number, name: string, title: string, order: number}[]>('/api/article/writing-team');
  }

  saveTeam(data: {id: number, name: string, title: string, order: number}[]): Observable<{id: number, name: string, title: string, order: number}[]> {
    return this.http.post<{id: number, name: string, title: string, order: number}[]>('/api/article/writing-team', data);
  }
}
