import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../services/user.service';
import {User} from '../../models/user.model';

@Injectable()
export class PermissionsResolver implements Resolve<Observable<User>> {

  constructor(private userService: UserService) { }

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot) {
    return this.userService.getProfile();
  }
}
