import { Pipe, PipeTransform } from '@angular/core';
import {GameTag} from '../../models/game-tag.model';

@Pipe({
  name: 'tagDisplayOnFilter'
})
export class TagDisplayOnFilterPipe implements PipeTransform {

  transform(tags: GameTag[]): any {
    return tags.filter(t => t.displayOnFilter);
  }

}
