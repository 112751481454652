import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ArrayNamesPipe} from './pipes/array-name-pipe.pipe';
import {
  AutoCompleterModule,
  ButtonsModule,
  CardsModule, CheckboxModule,
  FileInputModule,
  IconsModule,
  InputsModule, PreloadersModule,
  WavesModule
} from 'ng-uikit-pro-standard';
import {GameSearchComponent} from './components/game-search/game-search.component';
import {AuthorSearchComponent} from './components/author-search/author-search.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {UserInterceptor} from './interceptors/user.interceptor';
import {ImageUploadModalComponent} from './components/modals/image-upload-modal/image-upload-modal.component';
import {ImageUploadTriggerDirective} from './directives/image-upload-trigger.directive';
import { CropImageModalComponent } from './components/modals/crop-image-modal/crop-image-modal.component';
import {ScoreDisplayComponent} from './components/score-display/score-display.component';
import {OutletSearchComponent} from './components/outlet-search/outlet-search.component';
import {UtcDatePipe, UtcDatePipeFlex, UtcDatePipeShort} from './pipes/utc-date.pipe';
import {MaximumDistancePipe} from './pipes/maximum-distance.pipe';
import {SlugPipe} from './pipes/slug.pipe';
import {DashboardStatComponent} from './components/dashboard-stat/dashboard-stat.component';
import {RouterModule} from '@angular/router';
import { HtmlEditorModalComponent } from './components/modals/html-editor-modal/html-editor-modal.component';
import {QuillModule} from 'ngx-quill';
import { HtmlEditorTriggerDirective } from './directives/html-editor.trigger.directive';
import {BulkAuthorModalComponent} from './components/modals/bulk-author-modal/bulk-author-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TrailerSearchDirective } from './directives/trailer-search.directive';
import { TrailerSearchModalComponent } from './components/modals/trailer-search-modal/trailer-search-modal.component';
import { ChannelSearchModalComponent } from './components/modals/channel-search-modal/channel-search-modal.component';
import {ChannelSearchDirective} from './directives/channel-search.directive';
import {
  ExternalSourceAssociationModalComponent,
  ExternalSourcesWithErrors,
  ExternalSourcesWithResults
} from './components/modals/external-source-association-modal/external-source-association-modal.component';
import {ExternalSourceManualModalComponent} from './components/modals/external-source-manual-modal/external-source-manual-modal.component';
import { GameCloseModalComponent } from './components/modals/game-close-modal/game-close-modal.component';
import {CropImageTriggerDirective} from './directives/crop-image-trigger.directive';
import {CompanyTypePipe} from './pipes/company-type.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { BugReportModalComponent } from './components/modals/bug-report-modal/bug-report-modal.component';
import { BugReportTriggerDirective } from './directives/bug-report-trigger.directive';
import { ReviewOverrideModalComponent } from './components/modals/review-override-modal/review-override-modal.component';
import { AuthorSearchBootstrapComponent } from './components/author-search-bootstrap/author-search-bootstrap.component';
import { GameSearchBootstrapComponent } from './components/game-search-bootstrap/game-search-bootstrap.component';
import {NgxLoadingModule} from 'ngx-loading';
import {WordpressPluginDescriptionComponent} from "../pages/wordpress-plugin/wordpress-plugin-description/wordpress-plugin-description.component";
import { UriEncodedPipe } from './pipes/uri-encoded.pipe';
import {CropUploadImageTriggerDirective} from './directives/crop-upload-image-trigger.directive';
import { ImagePipePipe } from './pipes/image-pipe.pipe';
import {GameImageSetModalComponent} from "./components/modals/game-image-set-modal/game-image-set-modal.component";
import { TagDisplayOnFilterPipe } from './pipes/tag-display-on-filter.pipe';

@NgModule({
  declarations: [
    ArrayNamesPipe,
    GameSearchComponent,
    AuthorSearchComponent,
    ImageUploadModalComponent,
    OutletSearchComponent,
    ImageUploadTriggerDirective,
    ScoreDisplayComponent,
    CropImageModalComponent,
    UtcDatePipe,
    UtcDatePipeShort,
    UtcDatePipeFlex,
    MaximumDistancePipe,
    DashboardStatComponent,
    SlugPipe,
    HtmlEditorModalComponent,
    HtmlEditorTriggerDirective,
    BulkAuthorModalComponent,
    TrailerSearchDirective,
    ChannelSearchDirective,
    TrailerSearchModalComponent,
    ChannelSearchModalComponent,
    ExternalSourcesWithErrors,
    ExternalSourcesWithResults,
    ExternalSourceAssociationModalComponent,
    ExternalSourceManualModalComponent,
    GameCloseModalComponent,
    CropImageTriggerDirective,
    CompanyTypePipe,
    SafePipe,
    BugReportModalComponent,
    BugReportTriggerDirective,
    ReviewOverrideModalComponent,
    AuthorSearchBootstrapComponent,
    GameSearchBootstrapComponent,
    WordpressPluginDescriptionComponent,
    UriEncodedPipe,
    CropUploadImageTriggerDirective,
    ImagePipePipe,
    GameImageSetModalComponent,
    TagDisplayOnFilterPipe
  ],
  imports: [
    CommonModule,
    CardsModule,
    ButtonsModule,
    ImageCropperModule,
    IconsModule,
    AutoCompleterModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    WavesModule,
    FileInputModule,
    RouterModule,
    QuillModule,
    PreloadersModule,
    CheckboxModule,
    NgxLoadingModule
  ],
  exports: [
    ArrayNamesPipe,
    SlugPipe,
    CompanyTypePipe,
    GameSearchComponent,
    CropUploadImageTriggerDirective,
    UriEncodedPipe,
    AuthorSearchComponent,
    OutletSearchComponent,
    ImageUploadTriggerDirective,
    ScoreDisplayComponent,
    UtcDatePipe,
    UtcDatePipeShort,
    MaximumDistancePipe,
    UtcDatePipeFlex,
    DashboardStatComponent,
    HtmlEditorTriggerDirective,
    TrailerSearchDirective,
    ChannelSearchDirective,
    GameCloseModalComponent,
    CropImageTriggerDirective,
    SafePipe,
    BugReportTriggerDirective,
    ReviewOverrideModalComponent,
    GameImageSetModalComponent,
    AuthorSearchBootstrapComponent,
    GameSearchBootstrapComponent,
    WordpressPluginDescriptionComponent,
    TagDisplayOnFilterPipe,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserInterceptor,
      multi: true
    }
  ],
  entryComponents: [
    ImageUploadModalComponent,
    CropImageModalComponent,
    HtmlEditorModalComponent,
    BulkAuthorModalComponent,
    TrailerSearchModalComponent,
    ChannelSearchModalComponent,
    ExternalSourceAssociationModalComponent,
    ExternalSourceManualModalComponent,
    GameCloseModalComponent,
    BugReportModalComponent,
    ReviewOverrideModalComponent,
    GameImageSetModalComponent
  ]
})
export class SharedModule {
}
