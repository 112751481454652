import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {YouTubeChannel} from '../../../../models/youtube-channel.model';
import {Trailer} from '../../../../models/trailer.model';
import {YoutubeService} from '../../../services/youtube.service';

@Component({
  selector: 'app-trailer-search-modal',
  templateUrl: './trailer-search-modal.component.html',
  styleUrls: ['./trailer-search-modal.component.scss']
})
export class TrailerSearchModalComponent implements OnInit {
  @Input() initialSearch: string;
  @Input() channel: YouTubeChannel;
  @Input() title: string;
  @Input() initialTrailers: Trailer[];
  @Input() trailersSelected: EventEmitter<Trailer[]>;

  trailers: Trailer[];
  searchStr: string;
  searchChannelOnly: boolean;
  results: Trailer[][];
  loading = false;
  page: number;
  nextPageToken: string;

  constructor(private youtube: YoutubeService) {
  }

  ngOnInit() {
    this.trailers = [...this.initialTrailers];
    this.searchStr = '' + this.initialSearch;
    this.searchChannelOnly = !!(this.channel && this.channel.channelId);
    if (this.initialSearch) {
      this.search();
    }
  }

  trailerIndex(trailer: Trailer): number {
    return this.trailers.findIndex(t => t.videoId === trailer.videoId);
  }

  toggleTrailer(trailer: Trailer): void {
    const index = this.trailerIndex(trailer);
    if (index >= 0) {
      this.trailers.splice(index, 1);
    } else {
      this.trailers.unshift(trailer);
    }
    this.trailersSelected.emit(this.trailers);
  }

  search() {
    this.loading = true;
    this.results = [];
    this.page = 0;
    this.youtube.trailerSearch(this.searchStr, this.searchChannelOnly ? this.channel.channelId : undefined).subscribe(data => {
      this.results.push(data.videoResults);
      this.nextPageToken = data.nextPageToken;
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  nextPage() {
    this.loading = true;
    this.page++;
    if (this.page === this.results.length) {
      this.youtube.trailerSearch(this.searchStr, this.searchChannelOnly ? this.channel.channelId : undefined, this.nextPageToken).subscribe(data => {
        this.results.push(data.videoResults);
        this.nextPageToken = data.nextPageToken;
        this.loading = false;
      }, () => {
        this.page--;
        this.loading = false;
      });
    }
  }
}
