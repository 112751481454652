import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'companyType'
})
export class CompanyTypePipe implements PipeTransform {

  transform(value: any[], type: string): any {
    return value.filter(c => c.type === type);
  }

}
