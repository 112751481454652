import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Author} from '../../models/author.model';
import {Alias} from "../../models/alias.model";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthorService {

  constructor(private http: HttpClient) { }

  search(criteria: string): Observable<Author[]> {
    if (!criteria || criteria.length < 2) {
      return of([] as Author[]);
    }
    return this.http.get<Author[]>('/api/author/search?criteria=' + encodeURIComponent(criteria));
  }

  get(id: number | string): Observable<Author> {
    return this.http.get<Author>('/api/author/' + id);
  }

  save(author: Author): Observable<Author> {
    return this.http.post<Author>('/api/author', author);
  }

  newAuthor(name: string, override: boolean): Observable<Author> {
    return this.http.post<Author>('/api/author/new', {name, override});
  }

  getAlias (alias?: string, openCriticId?: number): Observable<Alias[]> {
    const params = [];
    if (alias) {
      params.push('alias=' + encodeURIComponent(alias));
    }
    if (openCriticId) {
      params.push('openCriticId=' +encodeURIComponent(openCriticId));
    }
    return this.http.get<Alias[]>('/api/alias?' + params.join('&'))
      .pipe(map((aliasArr: any[]) => {
        aliasArr.forEach(alias => {
          alias.author = {
            id: alias.openCriticId,
            name: alias.name
          } as Author;
        });
        return aliasArr as Alias[];
      }));
  }

  saveAlias (alias: Alias): Observable<Alias> {
    const payload = {... alias} as any;
    payload.openCriticId = alias.author ? alias.author.id : null;
    payload.name = alias.author ? alias.author.name : null;
    return this.http.post<Alias>('/api/alias', payload)
      .pipe(map((alias: any) => {
        alias.author = {
          id: alias.openCriticId,
          name: alias.name
        } as Author;
        return alias as Alias;
      }));
  }
}
