import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

interface Counters {
  reviewCount: number;
  externalCount: number;
  skuCount: number;
  epicCount: number;
  userReviewCount: number;
  applicationCount: number;
  emakiTagCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private http: HttpClient) { }

  get(): Observable<Counters> {
    return this.http.get<Counters>('/api/general/count');
  }
}
