import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {UserService} from '../services/user.service';
import {user_id_cookie, user_token_cookie} from "../../../constants/user-account.constants";
import {map} from "rxjs/operators";

@Injectable()
export class UserNotLoggedInGuard implements CanActivate {

  constructor(private router: Router, private cookie: CookieService, private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.cookie.check(user_token_cookie)) {
      // logged in so return true
      if (!this.userService.user) {
        return this.userService.getProfile().pipe(map(user => {
          return user.adminAccess
        }));
      }
      if (this.userService.hasAccess()) {
        this.router.navigate(['/']);
      } else {
        this.router.navigate(['/application']);
      }
      return false;
    }

    return true;
  }
}
