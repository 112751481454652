import { Component, isDevMode, ViewChild } from '@angular/core';
import { UserService } from './shared/services/user.service';
import { ModalDirective, SidenavComponent, ToastService } from 'ng-uikit-pro-standard';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BugReportService } from './shared/services/bug-report.service';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { ApplicationService } from './shared/services/application.service';
import { GameExternalService } from './shared/services/game-external.service';
import { ReviewService } from './shared/services/review.service';
import { GeneralService } from './shared/services/general.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Hawkeye';
  loading: boolean;
  @ViewChild('bugReportModal', { static: true }) bugReportModal: ModalDirective;
  @ViewChild('sidenav', { static: true }) sidenav: SidenavComponent;

  applicationCount: number;
  reviewCount: number;
  skuCount: number;
  sourceCount: number;
  chosenCount: number;
  userReviewCount: number;
  emakiTagCount: number;

  constructor(public userService: UserService,
              private generalService: GeneralService,
              private router: Router,
              private toastr: ToastService,
              public angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
              private bugReportService: BugReportService) {
    angulartics2GoogleAnalytics.startTracking();
    this.userService.getProfile().subscribe(() => {
      this.refreshCounters();
    });
    this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.loading = false;
        } else if (event instanceof NavigationStart) {
          this.loading = true;
          this.refreshCounters();
          if (window.innerWidth <= 1440) {
            this.sidenav.hide();
          }
        } else if (event instanceof NavigationError || event instanceof NavigationCancel) {
          this.loading = false;
        }
      });

  }

  refreshCounters() {
    if (this.userService.hasPermission('user:manage') ||
      this.userService.hasPermission('external-source:manage') ||
      this.userService.hasPermission('review:admin:manage') ||
      this.userService.hasPermission('ADMIN:ONLY')) {
      this.generalService.get().subscribe(data => {
        this.applicationCount = data.applicationCount;
        this.reviewCount = data.reviewCount;
        this.skuCount = data.skuCount;
        this.sourceCount = data.externalCount;
        this.chosenCount = data.epicCount;
        this.userReviewCount = data.userReviewCount;
        this.emakiTagCount = data.emakiTagCount;
      });
    }
  }
}
