import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
  MDBBootstrapModulesPro,
  ToastModule,
} from 'ng-uikit-pro-standard';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {UserInterceptor} from './shared/interceptors/user.interceptor';
import {CookieService} from 'ngx-cookie-service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime-ex';
import {QuillModule} from 'ngx-quill';
import {ImageService} from './shared/services/image.service';
import {GameService} from './shared/services/game.service';
import {AuthorService} from './shared/services/author.service';
import {SharedModule} from './shared/shared.module';
import {PermissionsResolver} from './shared/resolvers/pemissions.resolver';
import {UserService} from './shared/services/user.service';
import {ArticleService} from './shared/services/article.service';
import {LoginComponent} from './pages/login/login.component';
import {UserLoggedInGuard} from './shared/guards/logged-in.guard';
import {UserNotLoggedInGuard} from './shared/guards/not-logged-in.guard';
import {NgbDateAdapter, NgbDateNativeUTCAdapter} from '@ng-bootstrap/ng-bootstrap';
import {ErrorResponseInterceptor} from './shared/interceptors/response.interceptor';
import {SocialService} from './shared/services/social.service';
import {BugReportService} from './shared/services/bug-report.service';
import {FormsModule} from '@angular/forms';
import {Angulartics2Module} from 'angulartics2';
import {NgxLoadingModule} from 'ngx-loading';
import {HomepageService} from './shared/services/homepage.service';
import {ApplicationService} from './shared/services/application.service';
import {GameExternalService} from './shared/services/game-external.service';
import {YoutubeService} from './shared/services/youtube.service';
import { WordpressPluginComponent } from './pages/wordpress-plugin/wordpress-plugin.component';
import { WordpressPluginDescriptionComponent } from './pages/wordpress-plugin/wordpress-plugin-description/wordpress-plugin-description.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    WordpressPluginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ToastModule.forRoot({opacity: 1}),
    QuillModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    FormsModule,
    NgxLoadingModule.forRoot({}),
    Angulartics2Module.forRoot(),
  ],
  providers: [
    CookieService,
    ArticleService,
    YoutubeService,
    ImageService,
    GameService,
    AuthorService,
    GameExternalService,
    SocialService,
    BugReportService,
    ApplicationService,
    UserService,
    HomepageService,
    UserLoggedInGuard,
    UserNotLoggedInGuard,
    PermissionsResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorResponseInterceptor,
      multi: true
    },
    {
      provide: NgbDateAdapter,
      useClass: NgbDateNativeUTCAdapter
    }
  ],
  exports: [
    WordpressPluginDescriptionComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
