import {Component, Input, OnInit} from '@angular/core';
import {Game} from '../../../../models/game.model';
import {Subject} from 'rxjs';
import {MDBModalRef} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-game-close-modal',
  templateUrl: './game-close-modal.component.html',
  styleUrls: ['./game-close-modal.component.scss']
})
export class GameCloseModalComponent implements OnInit {
  @Input() results: Game[];
  @Input() newName: string;
  action: Subject<{pass: boolean}> = new Subject();
  confirmed: boolean;

  constructor(public activeModal: MDBModalRef) { }

  ngOnInit() {
  }

  confirm() {
    this.action.next({pass: true});
  }
}
