import {Component, Input, OnInit, Pipe, PipeTransform} from '@angular/core';
import {ExternalSource, ExternalSourceSearchResult} from '../../../../models/game-external.model';
import {MDBModalRef} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-external-source-association-modal',
  templateUrl: './external-source-association-modal.component.html',
  styleUrls: ['./external-source-association-modal.component.scss']
})
export class ExternalSourceAssociationModalComponent implements OnInit {
  @Input() results: ExternalSourceSearchResult[];
  @Input() selectedSources: ExternalSource[];
  consideredSource: ExternalSourceSearchResult;

  constructor(public activeModal: MDBModalRef) {
  }


  ngOnInit() {
    const sourcesWithResults = this.results.filter(result => result.results && result.results.length > 0);
    if (sourcesWithResults.length > 0) {
      this.consideredSource = sourcesWithResults[0];
    } else {
      this.consideredSource = null;
    }
  }

  numGoodResults(): number {
    return this.results.filter(result => !result.isError).length;
  }

  numErrors(): number {
    return this.results.filter(result => result.isError).length;
  }

  numWithSearchResults(): number {
    return this.results.filter(result => !result.isError && result.results && result.results.length > 0).length;
  }

  selectResult(result: ExternalSource): void {
    const i = this.selectedIndex(result);
    if (i >= 0) {
      this.selectedSources.splice(i, 1);
    } else {
      this.selectedSources.push(result);
    }
  }

  selectedIndex(result: ExternalSource): number {
    return this.selectedSources.findIndex(selection => selection.sourceId === result.sourceId &&
      selection.identifier === result.identifier);
  }

  next(): void {
    const current = this.getSourceIndex(this.consideredSource.provider.sourceId);
    const eligible = this.results.filter((result, index) => (index > current && result.results && result.results.length > 0));
    if (eligible && eligible.length) {
      this.consideredSource = eligible[0];
    } else {
      this.consideredSource = null;
    }
  }

  prev(): void {
    const current = (this.consideredSource) ? this.getSourceIndex(this.consideredSource.provider.sourceId) : 100000;
    const eligible = this.results.filter((result, index) => (index < current && result.results && result.results.length > 0));
    if (eligible && eligible.length) {
      this.consideredSource = eligible[eligible.length - 1];
    }
  }

  getNumPrev(): number {
    const current = (this.consideredSource) ? this.getSourceIndex(this.consideredSource.provider.sourceId) : 100000;
    const eligible = this.results.filter((result, index) => (index < current && result.results && result.results.length > 0));
    return eligible.length;
  }

  getSourceIndex(id: string): number {
    let index = -1;
    this.results.forEach((result, i) => {
      if (result.provider.sourceId === id) {
        index = i;
      }
    });
    return index;
  }
}


@Pipe({
  name: 'DataSourcesWithErrors',
  pure: false
})
export class ExternalSourcesWithErrors implements PipeTransform {
  transform(items: any[], filter: any): any {
    if (!items) {
      return items;
    }
    return items.filter(item => !!item.isError === filter);
  }
}


@Pipe({
  name: 'DataSourcesWithResults',
  pure: false
})
export class ExternalSourcesWithResults implements PipeTransform {
  transform(items: any[], filter: any): any {
    if (!items) {
      return items;
    }
    return items.filter(item => !item.isError && item.results && item.results.length > 0);
  }
}
