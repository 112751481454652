import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dashboard-stat',
  templateUrl: './dashboard-stat.component.html',
  styleUrls: ['./dashboard-stat.component.css']
})
export class DashboardStatComponent implements OnInit {
  @Input() count: number;
  @Input() color: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() dest: string;
  @Input() queryParams?: any;

  constructor() { }

  ngOnInit() {
  }

}
