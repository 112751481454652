import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Game} from '../../../models/game.model';
import {GameService} from '../../services/game.service';

@Component({
  selector: 'app-game-search-bootstrap',
  templateUrl: './game-search-bootstrap.component.html',
  styleUrls: ['./game-search-bootstrap.component.scss']
})
export class GameSearchBootstrapComponent implements OnInit, OnChanges {
  @Input() minLength ? = 3;
  @Input() initialGame ?: Game;
  @Output() selected = new EventEmitter<Game>();
  @Input() required ?= false;
  searchResults: Game[];
  searchStr: string;
  mostRecentResults: number;
  gameValue: Game;

  @Output()
  gameChange = new EventEmitter<Game>();

  @Input()
  get game() {
    return this.gameValue;
  }

  set game(val) {
    this.gameValue = val;
    this.gameChange.emit(this.gameValue);
  }

  constructor(private gameService: GameService) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.game) {
      this.searchStr = this.game.name;
    }
  }


  onChange() {
    this.selected.emit(null);
    if (this.searchStr && this.searchStr.length >= this.minLength) {
      const searchTime = Date.now();
      this.mostRecentResults = searchTime;
      this.gameService.search(this.searchStr).subscribe(results => {
        if (this.mostRecentResults <= searchTime) {
          this.mostRecentResults = searchTime;
          this.searchResults = results;
        }
      });
    } else {
      this.searchResults = null;
      this.mostRecentResults = Date.now();
    }
  }

  gameSelected(game: Game): void {
    this.mostRecentResults = null;
    this.searchResults = null;
    this.game = game;
    this.selected.emit(this.game);
  }
}
