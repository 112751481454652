import {Component, Input, OnInit} from '@angular/core';
import {Game} from "../../../../models/game.model";
import {MDBModalRef} from "ng-uikit-pro-standard";
import {Observable} from "rxjs";
import {OpenCriticImage} from "../../../../models/image.model";
import {ImageService} from "../../../services/image.service";

@Component({
  selector: 'app-game-image-set-modal',
  templateUrl: './game-image-set-modal.component.html',
  styleUrls: ['./game-image-set-modal.component.scss']
})
export class GameImageSetModalComponent implements OnInit {
  @Input() imageUrl: string;
  @Input() game: Game;
  @Input() onImageSubmitted: (obsv: Observable<OpenCriticImage>, type: string) => void;

  constructor(private modal: MDBModalRef, private imageService: ImageService) { }

  ngOnInit() {
  }

  onImageSubmitPrivate(obsv: Observable<OpenCriticImage>, type: string) {
    this.onImageSubmitted(obsv, type);
    this.modal.hide();
  }

  uploadImage(key: string) {
    const subscription = this.imageService.uploadByUrl(
      'game/' + this.game.id + '/image',
      'https://images.opencritic.com/' + this.imageUrl,
      undefined,
      key,
      'JPEG'
    );
    this.onImageSubmitted(subscription, key === 'screenshot' ? 'screenshots' : key);
    this.modal.hide();
  }
}
