import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthorService} from '../../services/author.service';
import {Observable} from 'rxjs';
import {Author} from '../../../models/author.model';
import {concatAll, debounceTime, map} from 'rxjs/operators';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-author-search',
    templateUrl: './author-search.component.html',
    styleUrls: ['./author-search.component.scss']
})
export class AuthorSearchComponent implements OnInit {
    results: Observable<Author[]>;
    authorSearchControl = new FormControl();
    authorValue: Author;
    @Input() label: string;
    @Input() clearOnSelection = false;
    @Input() tightMargins = false;
    @Input() small = false;

    @Output() selected = new EventEmitter<Author>();

    @Output()
    authorChange = new EventEmitter<Author>();

    @Input()
    get author() {
        return this.authorValue;
    }

    set author(val) {
        this.authorValue = val;
        this.authorChange.emit(this.authorValue);
    }

    constructor(private authorService: AuthorService) {
    }

    ngOnInit() {
        const client = this.authorService;
        if (this.author && !this.clearOnSelection) {
            this.authorSearchControl.setValue(this.author.name);
        }
        this.results = this.authorSearchControl.valueChanges.pipe(
            map(value => {
                if (value && value.name && value.id) {
                    this.author = value;
                    this.selected.emit(value);
                    if (this.clearOnSelection) {
                        this.authorValue = null;
                        this.authorSearchControl.setValue('');
                        return undefined;
                    }
                } else {
                    this.author = null;
                }
                return value;
            }),
            debounceTime(300),
            map(value => {
                if (typeof value === 'string') {
                    return value;
                }
                if (this.clearOnSelection) {
                    this.authorSearchControl.setValue('');
                }
                if (value) {
                    return value.name;
                }
                return value;
            }),
            map((value: string) => client.search(value)), concatAll());
    }

    onDisplayValue(author?: Author): string | undefined {
        return author ? author.name : undefined;
    }
}
