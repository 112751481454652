import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Review} from '../../../models/review.model';
import {ScoreFormatOptions} from '../../../models/score-format.model';

@Component({
  selector: 'app-score-display',
  templateUrl: './score-display.component.html',
  styleUrls: ['./score-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScoreDisplayComponent implements OnInit {
  @Input() review: Review;

  matchedFormat: any;
  display: string;
  numStars: number[];
  numEmptyStars: number[];
  hasHalfStar: boolean;

  constructor() { }

  ngOnInit() {
    this.matchedFormat = ScoreFormatOptions.filter(format => format.id === this.review.ScoreFormat.id)[0];
    if (this.review.ScoreFormat.id === 30) {
      this.display = 'Unscored';
    } else if (this.matchedFormat.isStars) {
      this.numStars = Array(Math.floor(this.review.score / this.matchedFormat.base));
      this.numEmptyStars = Array(Math.floor((100 - this.review.score) / this.matchedFormat.base));
      this.hasHalfStar = !(this.review.score % this.matchedFormat.base === 0);
    } else if (this.matchedFormat.isSelect) {
      let over = 101;
      this.matchedFormat.options.forEach(option => {
        if (option.val >= this.review.score && (option.val - this.review.score) < over) {
          over = option.val - this.review.score;
          this.display = option.label;
        }
      });
    }
  }

}
