import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OutletService} from '../../services/outlet.service';
import {Observable} from 'rxjs';
import {Outlet} from '../../../models/outlet.model';
import {concatAll, debounceTime, map} from 'rxjs/operators';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-outlet-search',
    templateUrl: './outlet-search.component.html',
    styleUrls: ['./outlet-search.component.scss']
})
export class OutletSearchComponent implements OnInit {
    results: Observable<Outlet[]>;
    outletSearchControl = new FormControl();
    outletValue: Outlet;
    @Input() label: string;
    @Input() clearOnSelection = false;
    @Input() tightMargins = false;

    @Output() selected = new EventEmitter<Outlet>();

    @Output()
    outletChange = new EventEmitter<Outlet>();

    @Input()
    get outlet() {
        return this.outletValue;
    }

    set outlet(val) {
        this.outletValue = val;
        this.outletChange.emit(this.outletValue);
    }

    constructor(private outletService: OutletService) {
    }

    ngOnInit() {
        const client = this.outletService;
        if (this.outlet && !this.clearOnSelection) {
            this.outletSearchControl.setValue(this.outlet.name);
        }
        this.results = this.outletSearchControl.valueChanges.pipe(
            map(value => {
                if (value && value.name && value.id) {
                    this.outlet = value;
                    this.selected.emit(value);
                    if (this.clearOnSelection) {
                        this.outletValue = null;
                        this.outletSearchControl.setValue('');
                        return undefined;
                    }
                } else {
                    this.outlet = null;
                }
                return value;
            }),
            debounceTime(300),
            map(value => {
                if (typeof value === 'string') {
                    return value;
                }
                if (this.clearOnSelection) {
                    this.outletSearchControl.setValue('');
                }
                if (value) {
                    return value.name;
                }
                return value;
            }),
            map((value: string) => client.search(value)), concatAll());
    }

    onDisplayValue(outlet?: Outlet): string | undefined {
        return outlet ? outlet.name : undefined;
    }
}
