import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {YouTubeChannel} from '../../models/youtube-channel.model';
import {Trailer} from '../../models/trailer.model';

@Injectable({
  providedIn: 'root'
})
export class YoutubeService {

  constructor(private http: HttpClient) { }

  channelSearch(query: string, nextPageToken?: string): Observable<{channelResults: YouTubeChannel[], nextPageToken: string}> {
    return this.http.post<{channelResults: YouTubeChannel[], nextPageToken: string}>('api/youtube/channel-search', {query, nextPageToken});
  }

  trailerSearch(query: string, channelId?: string, nextPageToken?: string): Observable<{videoResults: Trailer[], nextPageToken: string}> {
    return this.http.post<{videoResults: Trailer[], nextPageToken: string}>('api/youtube/search', {query, nextPageToken, channelId});
  }

}
