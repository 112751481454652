import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {HtmlEditorModalComponent} from '../components/modals/html-editor-modal/html-editor-modal.component';

@Directive({
  selector: '[appHtmlEditor]'
})
export class HtmlEditorTriggerDirective {
  @Input() heading: string;
  @Output() htmlSubmitted = new EventEmitter<{body: string, subject: string}>();

  modalRef: MDBModalRef;

  constructor(private elem: ElementRef, private modalService: MDBModalService) {
  }

  @HostListener('click') onClick() {
    this.modalRef = this.modalService.show(HtmlEditorModalComponent, {
      class: 'modal-lg modal-info modal-notify',
      data: {
        heading: this.heading
      }
    });
    this.modalRef.content.action.subscribe(d => {
      this.htmlSubmitted.emit(d);
    });
  }
}
