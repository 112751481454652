import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HomepageCarouselItem} from '../../models/home-carousel-item.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {

  constructor(private http: HttpClient) {
  }

  getAll(): Observable<HomepageCarouselItem[]> {
    return this.http.get<HomepageCarouselItem[]>('/api/home-carousel').pipe(map(items => {
      items.forEach(i => {
        if (i.embargoDate) {
          i.embargoDate = new Date(i.embargoDate);
        }
        if (i.deleteDate) {
          i.deleteDate = new Date(i.deleteDate);
        }
      });
      return items;
    }));
  }

  create(item: HomepageCarouselItem): Observable<HomepageCarouselItem> {
    return this.http.post<any>('/api/home-carousel', item);
  }

  save(item: HomepageCarouselItem): Observable<HomepageCarouselItem> {
    return this.http.put<any>('/api/home-carousel', item);
  }

  deleteCarouselItem(item: HomepageCarouselItem): Observable<HomepageCarouselItem> {
    return this.http.post<HomepageCarouselItem>('/api/home-carousel/delete', item);
  }
}
