import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {GameService} from '../../services/game.service';
import {Observable} from 'rxjs';
import {Game} from '../../../models/game.model';
import {concatAll, debounceTime, map} from 'rxjs/operators';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-game-search',
  templateUrl: './game-search.component.html',
  styleUrls: ['./game-search.component.scss']
})
export class GameSearchComponent implements OnInit {
  results: Observable<Game[]>;
  gameSearchControl = new FormControl();
  gameValue: Game;
  @Input() label: string;
  @Input() clearOnSelection = false;
  @Input() tightMargins = false;
  @Input() disabled = false;
  @Input() small = false;
  @Input() id = 'game-search-entry';

  @Output() selected = new EventEmitter<Game>();

  @Output()
  gameChange = new EventEmitter<Game>();

  @Input()
  get game() {
    return this.gameValue;
  }

  set game(val) {
    this.gameValue = val;
    this.gameChange.emit(this.gameValue);
  }

  constructor(private gameService: GameService) {
  }


  ngOnInit() {
    const client = this.gameService;
    if (this.game && !this.clearOnSelection) {
      this.gameSearchControl.setValue(this.game.name);
    } else {
      this.gameSearchControl.setValue('');
    }
    this.results = this.gameSearchControl.valueChanges.pipe(
      map(value => {
        if (value && value.name && value.id) {
          this.game = value;
          this.selected.emit(value);
          if (this.clearOnSelection) {
            this.gameValue = null;
            this.gameSearchControl.setValue('');
            return undefined;
          }
        } else {
          this.game = null;
        }
        return value;
      }),
      debounceTime(300),
      map(value => {
        if (typeof value === 'string') {
          return value;
        }
        if (this.clearOnSelection) {
          this.gameSearchControl.setValue('');
        }
        if (value) {
          return value.name;
        }
        return value;
      }),
      map((value: string) => client.search(value)), concatAll());
  }

  onDisplayValue(game?: Game): string | undefined {
    return game ? game.name : undefined;
  }
}
