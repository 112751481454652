import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PermissionsResolver } from './shared/resolvers/pemissions.resolver';
import { LoginComponent } from './pages/login/login.component';
import { UserNotLoggedInGuard } from './shared/guards/not-logged-in.guard';
import { WordpressPluginComponent } from "./pages/wordpress-plugin/wordpress-plugin.component";


const routes: Routes = [
  {
    path: 'wordpress-plugin',
    component: WordpressPluginComponent
  },
  {
    path: '',
    resolve: {
      user: PermissionsResolver
    },
    children: [
      {
        path: 'login',
        canActivate: [UserNotLoggedInGuard],
        component: LoginComponent,
      },
      {
        path: 'application',
        loadChildren: './pages/application/application.module#ApplicationModule'
      },
      {
        path: '',
        loadChildren: './pages/dashboard/dashboard.module#DashboardModule'
      }, {
        path: 'article',
        loadChildren: './pages/article/article.module#ArticleModule'
      }, {
        path: 'stories',
        loadChildren: './pages/stories/stories.module#StoriesModule'
      }, {
        path: 'social',
        loadChildren: './pages/social/social.module#SocialModule'
      }, {
        path: 'author',
        loadChildren: './pages/author/author.module#AuthorModule'
      }, {
        path: 'review',
        loadChildren: './pages/review/review.module#ReviewModule'
      }, {
        path: 'game',
        loadChildren: './pages/game/game.module#GameModule'
      }, {
        path: 'user',
        loadChildren: './pages/user-management/user-management.module#UserManagementModule'
      }, {
        path: 'homepage',
        loadChildren: './pages/homepage/homepage.module#HomepageModule'
      }, {
        path: 'external-source',
        loadChildren: './pages/external-source/external-source.module#ExternalSourceModule'
      }, {
        path: 'calendar',
        loadChildren: './pages/calendar/calendar.module#CalendarModule'
      }, {
        path: 'outlet',
        loadChildren: './pages/outlet/outlet.module#OutletModule'
      }, {
        path: 'wordpress-plugin-api-key',
        loadChildren: './pages/api-key/api-key.module#ApiKeyModule'
      },
      {
        path: 'moderation/games',
        loadChildren: './pages/user-review-moderation/user-review-moderation.module#UserReviewModerationModule'
      },
      {
        path: 'moderation/comments',
        loadChildren: './pages/user-comment-moderation/user-comment-moderation.module#UserCommentModerationModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
