import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {User} from '../../models/user.model';
import {CookieService} from 'ngx-cookie-service';
import {ToastService} from 'ng-uikit-pro-standard';
import {Router} from '@angular/router';
import {user_id_cookie, user_token_cookie} from '../../../constants/user-account.constants';
import {LoginResult} from '../../models/login-result.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: User;
  popup: Window;
  isLoggedIn: boolean;
  profileSub: any;
  public userId: number | void;
  private listenerAttached = false;

  constructor(private http: HttpClient,
              private cookie: CookieService,
              private router: Router,
              private toastr: ToastService) {
  }

  getProfile(clearCache?: boolean): Observable<User> {
    if (this.user && !clearCache) {
      return of(this.user);
    }
    if (!this.cookie.check(user_token_cookie)) {
      return of({} as User);
    }
    return this.http.get<User>('/api/user/profile').pipe(
        map(data => {
          this.user = data;
          return data;
        }));
  }

  getProfileLogged(): Observable<any> {
    return this.http.get<User>('/api/user/profile/logged');
  }

  hasPermission(str: string): boolean {
    return this.user && (this.user.isAdmin || this.user.permissions.includes(str));
  }

  hasAccess(): boolean {
    return this.user && this.user.adminAccess;
  }

  hasAuthorProfile(): boolean {
    return !!(this.user && this.user.PermittedAuthor && this.user.PermittedAuthor.id);
  }

  search(filter: any): Observable<User[]> {
    return this.http.post<User[]>('/api/user/search', filter);
  }

  saveUser(user: User): Observable<User> {
    const payload = {...user} as any;
    payload.PermittedAuthor = payload.PermittedAuthor ? payload.PermittedAuthor.id : null;
    payload.PermittedOutlets = payload.PermittedOutlets ? payload.PermittedOutlets.map(o => o.id) : [];
    return this.http.post<User>('/api/user/', payload);
  }

  getUserById(id: string): Observable<User> {
    return this.http.get<User>('/api/user/' + id);
  }

  getUserAdmins(id: string): Observable<User[]> {
    return this.http.get<User[]>('/api/user/admins');
  }

  getUserByUserId(id: number): Observable<User> {
    return this.http.get<User>('/api/user/user-id/' + id);
  }

  getAllPermissions(): Observable<{ code: string, label: string, category: string }[]> {
    return this.http.get<{ code: string, label: string, category: string }[]>('/api/permissions');
  }

  logout(): void {
    this.cookie.delete('user', '/', '.opencritic.com');
    this.cookie.delete('user_token', '/', '.opencritic.com');
    this.cookie.delete(user_token_cookie, '/', '.opencritic.com');
    this.cookie.delete(user_id_cookie, '/', '.opencritic.com');
    this.cookie.delete('user', '/', '.');
    this.cookie.delete('user_token', '/', '.');
    this.cookie.delete(user_token_cookie, '/', '.');
    this.cookie.delete(user_id_cookie, '/', '.');
    if (window.location.href.indexOf('localhost') >= 0) {
      this.cookie.delete('user_token', '/');
      this.cookie.delete(user_token_cookie, '/');
      this.cookie.delete(user_id_cookie, '/');
    }
    this.isLoggedIn = false;
    this.user = null;
    this.toastr.info('You have successfully logged out', null, {toastClass: 'toast-opaque'});
    this.router.navigate(['/login']);
  }


  login(method: string, email?: boolean): EventEmitter<LoginResult> {
    return this.popBox(true, 'You have successfully logged in', method, email);
  }


  connect(method: string, email?: boolean): EventEmitter<LoginResult> {
    return this.popBox(false, 'You have successfully connected your ' + method + ' account', method, email);
  }

  getUserId(): number | void {
    const id = this.cookie.get(user_id_cookie);
    if (!id) {
      return;
    }
    return +id;
  }

  private popBox(setCookies: boolean, message: string, method: string, email?: boolean): EventEmitter<LoginResult> {
    const emailStr = email ? 'email-true' : '';
    if (this.popup && this.popup.closed) {
      this.popup.close();
    }
    this.popup = window.open('https://api.opencritic.com/login/' + method + '?state=' + emailStr,
      'oc-login', 'width=560, height=600');

    const emitter = new EventEmitter<LoginResult>();
    const popup = this.popup;
    let closedNormally = false;

    const interval = setInterval(function () {
      if (popup.closed) {
        if (!closedNormally) {
          emitter.emit({
            method,
            success: true
          });
          emitter.complete();
        }

        clearInterval(interval);
      }
    }, 1000);

    if (!this.listenerAttached) {
      window.addEventListener('message', (event) => {
        if (event.origin.indexOf('https://api.opencritic.com') !== -1) {
          const date = new Date();
          date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
          let data;
          try {
            data = JSON.parse(event.data);
          } catch (err) {
            emitter.emit({
              method,
              success: true
            });
            emitter.complete();
            this.popup.close();
            clearInterval(interval);
            closedNormally = true;
            return false;
          }
          if (!data.token || !data.id) {
            emitter.emit({
              method,
              success: false,
              data
            });
            if (data.message) {
              if (data.message.mergeAccounts) {
                this.toastr.error('That profile is already connected to another OpenCritic account.');
              } else {
                this.toastr.error(data.message);
              }
            }
            emitter.complete();
            this.popup.close();
            clearInterval(interval);
            closedNormally = true;
            return false;
          }
          if (setCookies) {
            if (window.location.href.indexOf('localhost') >= 0) {
              this.cookie.set(user_token_cookie, data.token, date, '/');
              this.cookie.set(user_id_cookie, data.id, date, '/');
            }
            this.userId = data.id;
            this.cookie.set(user_token_cookie, data.token, date, '/', '.opencritic.com', true);
            this.cookie.set(user_id_cookie, data.id, date, '/', '.opencritic.com', true);
            this.isLoggedIn = true;
            this.getProfile(true).subscribe();
          }
          this.toastr.success(message);
          emitter.emit({
            method,
            success: true
          });
          emitter.complete();
          closedNormally = true;
          clearInterval(interval);
          this.popup.close();
        } else {
          closedNormally = true;
          emitter.emit({
            method,
            success: false
          });
          emitter.complete();
          clearInterval(interval);
        }
      });
      this.listenerAttached = true;
    }
    return emitter;
  }
}
