import {Directive, HostListener, Input} from '@angular/core';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {BugReportModalComponent} from '../components/modals/bug-report-modal/bug-report-modal.component';

@Directive({
  selector: '[appBugReportTrigger]'
})
export class BugReportTriggerDirective {
  @Input() title = 'Submit Bug Report';
  @Input() inputLabel = 'Bug Report';
  @Input() description = 'We\'re sorry you encountered a bug! Please provide as much detail as you can, and we\'ll work on it asap.';

  modalRef: MDBModalRef;

  constructor(private modalService: MDBModalService) { }

  @HostListener('click') onClick() {
    this.modalRef = this.modalService.show(BugReportModalComponent, {
      class: 'modal-lg modal-info modal-notify',
      data: {
        title: this.title,
        inputLabel: this.inputLabel,
        description: this.description
      }
    });
  }
}
