import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {ImageService} from '../../../services/image.service';
import {Observable, Observer, Subject} from 'rxjs';
import {OpenCriticImage} from '../../../../models/image.model';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-crop-image-modal',
  templateUrl: './crop-image-modal.component.html',
  styleUrls: ['./crop-image-modal.component.scss']
})
export class CropImageModalComponent implements OnInit {
  @Input() imageUrl: string;
  @Input() targetWidth?: number;
  @Input() targetHeight?: number;
  @Input() targetAspectRatio?: number;
  @Input() fileType?: string;
  @Input() endpoint: string;
  @Input() thumbnailWidth ?: number;
  @Input() thumbnailHeight ?: number;
  @Input() key ?: string;
  // @ViewChild('cropper', {static: false}) public cropper: CropperComponent;
  cropOptions: any;
  base64Image: any;
  cropPosition: any;
  loading: boolean;

  action: Subject<Observable<OpenCriticImage>> = new Subject();

  constructor(public activeModal: MDBModalRef,
              private imageService: ImageService) { }

  ngOnInit() {
    this.cropOptions = {
      viewMode: 1,
      checkCrossOrigin: false,
      zoomOnWheel: false,
      autoCropArea: 1,
      minCanvasWidth: 300,
      minCanvasHeight: 300,
    };
    if (this.targetAspectRatio) {
      this.cropOptions.aspectRatio = this.targetAspectRatio;
    } else if (this.targetWidth && this.targetHeight) {
      this.targetAspectRatio = this.targetWidth / this.targetHeight;
    }

    this.loading = true;
    this.getBase64ImageFromURL(this.imageUrl).subscribe(base64data => {
      // this is the image as dataUrl
      this.base64Image = 'data:image/jpg;base64,' + base64data;
      this.loading = false;
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.cropPosition = event.imagePosition;
  }

  submit() {
    // const data = this.cropper.cropper.getData();
    const cropData = {
      left : Math.round(this.cropPosition.x1),
      top : Math.round(this.cropPosition.y1),
      cropWidth : Math.round(this.cropPosition.x2 - this.cropPosition.x1),
      cropHeight : Math.round(this.cropPosition.y2 - this.cropPosition.y1)
    } as any;
    if (this.targetHeight && this.targetWidth) {
      cropData.resizeHeight = this.targetHeight;
      cropData.resizeWidth = this.targetWidth;
    }
    const subscription = this.imageService.uploadByUrl(
      this.endpoint,
      this.imageUrl,
      cropData,
      this.key,
      this.fileType,
      this.thumbnailWidth,
      this.thumbnailHeight);
    this.action.next(subscription);
    this.action.complete(); // Might be a bad idea?
    this.activeModal.hide();
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      // Workaround for non-public S3 bucket
      const fixedUrl = url.replace("https://s3.amazonaws.com/images.opencritic.com", "https://images.opencritic.com");
      // create an image object
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = '/api/image-proxy?url=' + encodeURIComponent(fixedUrl);
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    const dataURL = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
}
