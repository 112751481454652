export interface ScoreFormat {
  name: string;
  shortName: string;
  isNumeric: boolean;
  isSelect: boolean;
  numDecimals: 0;
  id: number;
  base: number;
  isStars: boolean;
  scoreDisplay: string;
  options: {
    val: number;
    label: string;
  }[];
}

export const ScoreFormatOptions = [
  {
    id: 17,
    name: '0 to 100, whole numbers',
    shortName: 'x / 100',
    scoreDisplay: ' / 100',
    isNumeric: true,
    isSelect: false,
    isStars: false,
    numDecimals: 0,
    base: 1,
    options: null
  }, {
    id: 18,
    name: '0 to 10 incl decimals',
    shortName: 'x.x / 10.0',
    scoreDisplay: ' / 10.0',
    isNumeric: true,
    isSelect: false,
    isStars: false,
    numDecimals: 1,
    base: 10,
    options: null
  }, {
    id: 19,
    name: '0 to 5 stars, incl half stars',
    scoreDisplay: ' / 5 stars',
    shortName: 'x.5 / 5 stars',
    isNumeric: true,
    isSelect: false,
    isStars: true,
    numDecimals: 2,
    base: 20,
    options: null
  }, {
    id: 20,
    name: 'Percentage',
    shortName: 'x%',
    scoreDisplay: '%',
    isNumeric: true,
    isSelect: false,
    isStars: false,
    numDecimals: 0,
    base: 1,
    options: null
  }, {
    id: 21,
    name: '0 to 5, whole numbers',
    shortName: 'x / 5',
    scoreDisplay: ' / 5',
    isNumeric: true,
    isSelect: false,
    isStars: false,
    numDecimals: 0,
    base: 20,
    options: null
  }, {
    id: 22,
    name: '0 to 5 stars, whole stars',
    shortName: 'x / 5 stars',
    scoreDisplay: ' / 5 stars',
    isNumeric: true,
    isSelect: false,
    isStars: true,
    numDecimals: 0,
    base: 20,
    options: null
  }, {
    id: 23,
    name: '0 to 10 stars, whole stars',
    shortName: 'x / 10 stars',
    scoreDisplay: ' / 10 stars',
    isNumeric: true,
    isSelect: false,
    isStars: true,
    numDecimals: 0,
    base: 10,
    options: null
  }, {
    id: 24,
    name: '0 to 5, incl decimals',
    shortName: 'x.x / 5',
    scoreDisplay: ' / 5.0',
    isNumeric: true,
    isSelect: false,
    isStars: false,
    numDecimals: 2,
    base: 20,
    options: null
  }, {
    id: 25,
    name: '0 to 10, whole numbers',
    shortName: 'x / 10',
    scoreDisplay: ' / 10',
    isNumeric: true,
    isSelect: false,
    isStars: false,
    base: 10,
    options: null
  }, {
    id: 26,
    name: '0 to 20, whole numbers',
    shortName: 'x / 20',
    scoreDisplay: ' / 20',
    isNumeric: true,
    isSelect: false,
    isStars: false,
    numDecimals: 0,
    base: 5,
    options: null
  }, {
    id: 28,
    name: 'Yes, Not Yet, No',
    shortName: 'Yes/No/Not Yet',
    scoreDisplay: null,
    isNumeric: false,
    isSelect: true,
    isStars: false,
    numDecimals: null,
    base: null,
    options: [
      {
        val: 100,
        label: 'Yes'
      }, {
        val: 40,
        label: 'Not Yet'
      }, {
        val: 0,
        label: 'No'
      }
    ]
  }, {
    id: 29,
    name: 'Letter Grading',
    shortName: 'Letter Grading',
    scoreDisplay: null,
    isNumeric: false,
    isSelect: true,
    isStars: false,
    numDecimals: null,
    base: null,
    options: [
      {
        val: 100,
        label: 'A or higher'
      }, {
        val: 91,
        label: 'A-'
      }, {
        val: 83,
        label: 'B+'
      }, {
        val: 75,
        label: 'B'
      }, {
        val: 67,
        label: 'B-'
      }, {
        val: 58,
        label: 'C+'
      }, {
        val: 50,
        label: 'C'
      }, {
        val: 42,
        label: 'C-'
      }, {
        val: 33,
        label: 'D+'
      }, {
        val: 25,
        label: 'D'
      }, {
        val: 16,
        label: 'D-'
      }, {
        val: 0,
        label: 'F'
      }
    ]
  }, {
    id: 31,
    name: 'Essential, Recommended, No Rec, Avoid',
    shortName: 'Essential <--> Avoid',
    scoreDisplay: null,
    isNumeric: false,
    isSelect: true,
    isStars: false,
    numDecimals: null,
    base: null,
    options: [
      {
        val: 100,
        label: 'Essential'
      }, {
        val: 80,
        label: 'Recommended'
      }, {
        val: 75,
        label: 'No Recommendation / Blank'
      }, {
        val: 0,
        label: 'Avoid'
      }
    ]
  }, {
    id: 32,
    name: 'Buy, Wait for Sale, Rent, Never Touch',
    shortName: 'Buy/Wait/Rent/Avoid',
    scoreDisplay: null,
    isNumeric: false,
    isSelect: true,
    isStars: false,
    numDecimals: null,
    base: null,
    options: [
      {
        val: 100,
        label: 'Buy'
      }, {
        val: 75,
        label: 'Wait for Sale'
      }, {
        val: 50,
        label: 'Rent'
      }, {
        val: 0,
        label: 'Never Touch'
      }
    ]
  }, {
    id: 33,
    name: 'Buy, Wait, Don\'t Buy',
    shortName: 'Buy/Wait/Don\'t Buy',
    scoreDisplay: null,
    isNumeric: false,
    isSelect: true,
    isStars: false,
    numDecimals: null,
    base: null,
    options: [
      {
        val: 100,
        label: 'Buy'
      }, {
        val: 70,
        label: 'Wait'
      }, {
        val: 0,
        label: 'Don\'t Buy'
      }
    ]
  }, {
    id: 34,
    name: 'Loved, Liked, Meh, Hated, etc.',
    shortName: 'Loved < Liked < Hated',
    scoreDisplay: null,
    isNumeric: false,
    isSelect: true,
    isStars: false,
    numDecimals: null,
    base: null,
    options: [
      {
        val: 100,
        label: 'Loved'
      }, {
        val: 80,
        label: 'Liked-a-lot'
      }, {
        val: 75,
        label: 'Liked'
      }, {
        val: 60,
        label: 'Meh'
      }, {
        val: 65,
        label: 'Mixed'
      }, {
        val: 95,
        label: 'Mind-blown'
      }, {
        val: 40,
        label: 'Disliked'
      }, {
        val: 0,
        label: 'Hated'
      }
    ]
  }, {
    id: 35,
    name: 'Masterpiece, Worth your time, ???, Caution, Avoid',
    shortName: 'Masterpiece/Worth/???/Caution/Avoid',
    scoreDisplay: null,
    isNumeric: false,
    isSelect: true,
    isStars: false,
    numDecimals: null,
    base: null,
    options: [
      {
        val: 100,
        label: 'Masterpiece'
      }, {
        val: 80,
        label: 'Worth your time'
      }, {
        val: 60,
        label: 'Maybe'
      }, {
        val: 50,
        label: 'Caution'
      }, {
        val: 10,
        label: 'Avoid'
      }
    ]
  },
  {
    id: 30,
    name: 'No Verdict',
    shortName: 'No Verdict',
    scoreDisplay: ' / 100',
    isNumeric: false,
    isSelect: false,
    isStars: false,
    numDecimals: null,
    base: null,
    options: null
  }
];
