import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slug'
})
export class SlugPipe implements PipeTransform {

  transform(name: string): any {
    return name ? name.replace(/'/g, '')
      .replace(/[^a-z0-9]/ig, '-')
      .replace(/\-+/g, '-').toLowerCase() : name;
  }
}
