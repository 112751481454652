import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxDistance'
})
export class MaximumDistancePipe implements PipeTransform {

  transform(value: any[], dist: number): any {
    return value.filter(v => v.dist <= dist);
  }

}
