import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {YouTubeChannel} from '../../models/youtube-channel.model';
import {Trailer} from '../../models/trailer.model';
import {TrailerSearchModalComponent} from '../components/modals/trailer-search-modal/trailer-search-modal.component';
import {MDBModalService} from 'ng-uikit-pro-standard';

@Directive({
  selector: '[appTrailerSearchTrigger]'
})
export class TrailerSearchDirective {
  @Input() initialSearch: string;
  @Input() title: string;
  @Input() channel: YouTubeChannel;
  @Input() initialTrailers: Trailer[];
  @Output() trailersSelected  = new EventEmitter<Trailer[]>();

  constructor(private elem: ElementRef, private modalService: MDBModalService) {
  }

  @HostListener('click') onClick() {
    const modalRef = this.modalService.show(TrailerSearchModalComponent, {
      class: 'modal-xl modal-info modal-notify',
      data: {
        initialSearch: this.initialSearch,
        channel: this.channel,
        title: this.title,
        initialTrailers: this.initialTrailers,
        trailersSelected: this.trailersSelected
      }
    });
  }
}
