import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Game} from '../../models/game.model';
import {Observable, of} from 'rxjs';
import {Platform} from '../../models/platform.model';
import {GameSequence} from '../../models/game-sequence.model';
import {map} from 'rxjs/operators';
import {GameSequenceGameConsideration} from '../../models/game-sequence-game-consideration.model';
import {GameSequenceAlias} from '../../models/game-sequence-alias.model';
import {GameTag} from '../../models/game-tag.model';
import {ExternalSource, GameExternal} from '../../models/game-external.model';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  constructor(private http: HttpClient) {
  }

  static transformGame(game: Game): Game {
    let first: Date;
    if (game.Platforms && game.Platforms.length) {
      game.Platforms.forEach(p => {
        if (p.releaseDate) {
          p.releaseDate = new Date(p.releaseDate);
        }
        if (p.releaseDate && (!first || first.getTime() > p.releaseDate.getTime())) {
          first = p.releaseDate;
        }
      });
    } else {
      game.Platforms = [];
    }
    if (!game.Rating || !game.Rating.value) {
      game.Rating = {} as {value: string, imageSrc: string};
    }
    if (!game.monetizationFeatures) {
      game.monetizationFeatures = {hasLootBoxes: false};
    }
    if (game.firstReleaseDate) {
      game.firstReleaseDate = new Date(game.firstReleaseDate);
    } else if (first) {
      game.firstReleaseDate = first;
    } else {
      game.firstReleaseDate = new Date();
    }
    if (game.trailers) {
      game.trailers.forEach(t => {
        if (t.publishedDate) {
          t.publishedDate = new Date(t.publishedDate);
        }
      });
      // sort game trailers by publishedDate Desc;
      game.trailers = game.trailers.sort((a, b) => {
        if (a.publishedDate.getTime() > b.publishedDate.getTime()) {
          return -1;
        }
        if (a.publishedDate.getTime() < b.publishedDate.getTime()) {
          return 1;
        }
        return 0;
      });
    }
    return game;
  }

  static transformGames(games: Game[]): Game[] {
    return games.map(GameService.transformGame);
  }

  search(criteria: string): Observable<Game[]> {
    if (!criteria || criteria.length < 2) {
      return of([] as Game[]);
    }
    return this.http.get<Game[]>('/api/game/search?criteria=' + encodeURIComponent(criteria));
  }

  getPlatforms(): Observable<Platform[]> {
    return this.http.get<Platform[]>('/api/platform');
  }

  getAllGameSequences(): Observable<GameSequence[]> {
    return this.http.get<GameSequence[]>('/api/game-sequence');
  }

  getGameSequence(key: string): Observable<GameSequence> {
    return this.http.get<GameSequence>('/api/game-sequence/' + key);
  }

  getGameSequenceGames(key: string): Observable<Game[]> {
    return this.http.get<Game[]>('/api/game-sequence/game/' + key);
  }

  saveGameSequence(seq: GameSequence): Observable<GameSequence> {
    const sequence = {key: seq.key, games: seq.games.map(g => ({id: g.id, name: g.name}))};
    return this.http.post<GameSequence>('/api/game-sequence', sequence);
  }

  getRecentlyAddedGames(): Observable<Game[]> {
    return this.http.get<Game[]>('/api/game/recently-added');
  }

  getById(id: number): Observable<Game> {
    return this.http.get<Game>('/api/game/' + id).pipe(map(GameService.transformGame));
  }

  getSquareImageQueue(id: number | string): Observable<Game> {
    return this.http.get<Game>('/api/game/next-needing-square/' + id).pipe(map(GameService.transformGame));
  }

  getSquareImageQueueCount(): Observable<{ count: number }> {
    return this.http.get<{ count: number }>('/api/game/next-needing-square/count');
  }

  getVerticalLogoQueue(id: number | string): Observable<Game> {
    return this.http.get<Game>('/api/game/next-needing-vertical/' + id).pipe(map(GameService.transformGame));
  }

  getVerticalLogoCount(): Observable<{ count: number }> {
    return this.http.get<{ count: number }>('/api/game/next-needing-vertical/count');
  }

  getMastheadImageQueue(id: number | string): Observable<Game> {
    return this.http.get<Game>('/api/game/next-needing-masthead/' + id).pipe(map(GameService.transformGame));
  }

  getMastheadImageCount(): Observable<{ count: number }> {
    return this.http.get<{ count: number }>('/api/game/next-needing-masthead/count');
  }

  saveGame(game: Game): Observable<Game> {
    return this.http.post<Game>('/api/game', game);
  }

  saveTrailers(game: Game): Observable<Game> {
    return this.http.post<Game>('/api/game/trailers', game);
  }

  getGameTags(): Observable<GameTag[]> {
    return this.http.get<GameTag[]>('/api/game-tag');
  }

  getGamesNeedingTagsCount(): Observable<{count: number}> {
    return this.http.get<{count: number}>('/api/game-tag/game/count');
  }

  getNextGameNeedingTags(): Observable<{ game: Game, sources: GameExternal }> {
    return this.http.get<{ game: Game, sources: GameExternal }>('/api/game-tag/game/next');
  }

  getGameTagInformation(id: string): Observable<{tag: GameTag, games: Game[]}>{
    return this.http.get<{tag: GameTag, games: Game[]}>('/api/game-tag/' + id);
  }

  saveGameTag(tag: GameTag): Observable<GameTag> {
    return this.http.put<GameTag>('/api/game-tag', tag);
  }

  createGameTag(tag: GameTag): Observable<GameTag> {
    return this.http.post<GameTag>('/api/game-tag', tag);
  }

  saveGameTags(id: number, tags: string[]): Observable<Game> {
    return this.http.post<Game>('/api/game-tag/game', {id, tags});
  }

  deleteGameTag(tag: GameTag): Observable<GameTag> {
    return this.http.delete<GameTag>('/api/game-tag/' + tag._id);
  }

  addTagToGame(tagId: string, gameId: number): Observable<Game> {
    return this.http.post<Game>('/api/game-tag/add', {tagId, gameId});
  }

  forceUpdateGame(id: number): Observable<Game> {
    return this.http.post<Game>('/api/game/force-update', {id});
  }

  removeTagFromGame(tagId: string, gameId: number): Observable<Game> {
    return this.http.post<Game>('/api/game-tag/remove', {tagId, gameId});
  }

  deleteGame(id: number): Observable<any> {
    return this.http.post<Game>('/api/game/delete', {id});
  }

  getGameReviewSummary(id: string): Observable<Game> {
    return this.http.get<Game>('/api/review-summary/' + id).pipe(map(GameService.transformGame));
  }

  getPersonalGamesReviewed(): Observable<Game[]> {
    return this.http.get<Game[]>('/api/review-summary/personal-total').pipe(map(GameService.transformGames));
  }

  getGameSequenceGameConsiderationsRiver(): Observable<GameSequenceGameConsideration[]> {
    return this.http.get<GameSequenceGameConsideration[]>('/api/game-sequence/river');
  }

  getNumGameSequenceGameConsiderations(): Observable<{count: number}> {
    return this.http.get<{count: number}>('/api/game-sequence/count');
  }

  getGameSequenceGameConsideration(id: string): Observable<GameSequenceGameConsideration> {
    return this.http.get<GameSequenceGameConsideration>('/api/game-sequence/consideration/' + id);
  }

  getGameSequenceAliasRiver(): Observable<GameSequenceAlias[]> {
    return this.http.get<GameSequenceAlias[]>('/api/game-sequence/alias/river');
  }

  saveGameConsiderationAlias(consideration: GameSequenceGameConsideration, alias: GameSequenceAlias): Observable<GameSequenceGameConsideration> {
    return this.http.post<GameSequenceGameConsideration>('/api/game-sequence/consideration',
      {consideration, alias});
  }
}
