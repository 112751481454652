import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {OpenCriticImage} from '../../models/image.model';
import {HttpClient} from '@angular/common/http';
import {CropInstructions} from '../../models/crop-instructions.model';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(private http: HttpClient) { }

  uploadByUrl(
    endpoint: string,
    targetUrl: string,
    cropData?: CropInstructions,
    key ?: string,
    fileType ?: string,
    thumbnailWidth ?: number,
    thumbnailHeight ?: number
    ): Observable<OpenCriticImage> {
    return this.http.post<OpenCriticImage>('api/' + endpoint + '/url',
      {targetUrl, cropData, key, fileType, thumbnailHeight, thumbnailWidth},
      {headers: {'content-type': 'application/json'}});
  }

  getImageList(path: string, search?: string, ContinuationToken?: string, limit?: number):
    Observable<{Contents: string[], NextContinuationToken: string}> {
    return this.http.post<{Contents: string[], NextContinuationToken: string}>(
      '/api/image/search', {path: path + 'o/', search, ContinuationToken, limit}
    );
  }

  fileUpload(endpoint: string, file?: any): Observable<OpenCriticImage> {
    return this.http.post<OpenCriticImage>('api/' + endpoint + '/upload', file);
  }
}
